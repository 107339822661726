import TuneIcon from 'src/assets/icons/tune.svg';
import FinancialAnalyticsIcon from 'src/assets/icons/financial-analytics.svg';
import AgreementIcon from 'src/assets/icons/agreement.svg';
import PurposefulManIcon from 'src/assets/icons/purposeful-man.svg';

const content = {
  title: 'Wir über uns',
  headvisualHeadline: 'Wir über uns',
  geschichte: {
    topline: 'Wir über uns',
    headline: 'Unsere Geschichte',
    entries: [
      {
        year: '1996',
        headline: 'Gründung der NBB',
        paragraph:
          'Die NÜRNBERGER Business Beratungs GmbH (NBB) ist eine Gesellschaft im Konzern der NÜRNBERGER Versicherung. 1996 gegründet, lag von Beginn an unser Fokus auf den Bereichen betriebliche Altersversorgung und Personaldienstleistung. Seit Gründung ist die NBB Verwalter der NÜRNBERGER überbetriebliche Versorgungskasse e. V., einer der größten Unterstützungskassen Deutschlands. Für diese übernehmen wir u.a. die Rentnerverwaltung, d.h. Versteuerung und Verbeitragung aller Versorgungsleistungen, erstellt für alle Trägerunternehmen die Kurztestate zur Insolvenzsicherung beim Pensions-Sicherungs-Verein VVaG und trägt dafür Sorge, dass alle rechtlichen Rahmenbedingungen erfüllt werden.'
      },
      {
        year: '2000',
        headline: 'Firmenkunden',
        paragraph:
          'Zudem übernimmt die NBB für inzwischen über 2.000 Firmenkunden die Bewertung der Pensions- und ähnlichen Verpflichtungen, wie z.B. Jubiläumsverpflichtungen, nach handels- und steuerrechtlichen sowie internationalen Rechnungslegungsgrundsätzen. Im Zeitablauf konnten hierbei viele große, namenhafte Unternehmen von der Dienstleistungsqualität überzeugt werden. In dieser sehr engen Betreuung hat sich herauskristallisiert, wie wichtig es ist Versorgungswerke zur betrieblichen Altersversorgung laufend auf den Prüfstand zu stellen. Im Spannungsfeld zwischen Verwaltungsaufwand, wirtschaftlicher Belastung für Arbeitgeber und Attraktivität für die Arbeitnehmer, ist es wichtig auf Änderungen, im Arbeits- und Steuerrecht angemessen zu reagieren. Diesen Prozess begleitet die NBB und unterstützt ihre Kunden in allen Belangen.  Des Weiteren werden für alle Tochtergesellschaften der NÜRNBERGER Versicherung sowie für einige konzernfremde Gesellschaften die Personaldienstleistungen übernommen.'
      }
    ]
  },
  kompetenzen: {
    topline: 'Wir über uns',
    headline: 'Unsere Kompetenzen',
    leftParagraphs: [
      'Wer heute als Unternehmer seinen Beschäftigten keine betriebliche Altersversorgung (bAV) anbietet, lässt viele Vorteile ungenutzt. Um alle Chancen einer bAV ausschöpfen zu können, braucht es mehr als passende Produkte. Man braucht einen Partner, auf den man sich verlassen kann.',
      'Mit der NÜRNBERGER Business Beratungs GmbH (NBB) vertrauen Sie auf ein erfahrenes und kompetentes Team aus Spezialisten im Bereich der betrieblichen Altersversorgung sowie bei Personaldienstleistungen.',
      'Die NBB entwickelt für Sie betriebsindividuelle Lösungen und unterstützt Firmenkunden bei der Analyse sowie Modernisierung betrieblicher Versorgungswerke oder erstellt versicherungsmathematische Gutachten für die Steuer- und Handelsbilanz.'
    ],
    rightParagraphs: [
      'Dabei arbeitet die NBB mit renommierten Kooperationspartnern zusammen. So haben Sie vom Vertragsabschluss bis hin zur Versorgungsphase immer einen professionellen Ansprechpartner an Ihrer Seite.',
      'Zudem bietet die NBB alle Personaldienstleistungen an, die bei einem Outsourcen von Human Resource-Tätigkeiten benötigt werden könnten. Neben der reinen Lohn- und Gehaltsabrechnung für tätige Mitarbeiter sowie Rentner kann eine große Bandbreite an weiteren Dienstleistungen in Anspruch genommen werden.',
      '<strong>Profitieren Sie von unserem Wissen.</strong>'
    ],
    cards: [
      {
        icon: TuneIcon,
        headline: 'Betriebsindividuelle Lösungen'
      },
      {
        icon: FinancialAnalyticsIcon,
        headline: 'Analyse & Modernisierung betriebl. Versorgungswerke'
      },
      {
        icon: AgreementIcon,
        headline:
          'Erstellung von versicherungsmath. Gutachten für die Steuer- & Handelsbilanz'
      },
      {
        icon: PurposefulManIcon,
        headline: 'Personaldienstleistungen (z.B. Lohn- und Gehaltsabrechnung)'
      }
    ]
  },
  kontakt: {
    headline: 'Kontakt',
    paragraphs: [
      '<strong>Wir sind für Sie da</strong>',
      'Sie haben eine Frage und möchten schnell eine passende Lösung dafür erhalten? Oder Sie möchten uns einfach und schnell einen Auftrag erteilen?  Unser Team aus Spezialisten ist gerne für Sie da.'
    ],
    boxes: [
      {
        background: 'light-grey-blue',
        headline: 'Betriebliche Altersversorgung',
        paragraph:
          'Für alle Anfragen rund um das Thema <strong>Ausgestaltung betrieblicher Altersversorgung</strong>, insbesondere in den Durchführungswegen Direktversicherung, Unterstützungskasse und Pensionsfonds schreiben Sie uns einfach eine E-Mail.',
        href: 'mailto:nbb-dienstleistungen@nuernberger.de'
      },
      {
        background: 'light-pink',
        headline: 'Pensionszusagen und versicherungsmathematische Gutachten',
        paragraph:
          'Für alle Anfragen rund um das <strong>Thema Pensionszusagen und versicherungsmathematische Gutachten</strong> wenden Sie sich an unsere Hotline unter 0911-5314545 oder schreiben Sie uns einfach eine E-Mail.',
        href: 'mailto:nbb-dienstleistungen@nuernberger.de'
      },
      {
        background: 'light-blue',
        headline: 'Personaldienstleistungen',
        paragraph:
          'Für alle Anfragen rund um das <strong>Thema Personaldienstleistungen</strong> schreiben Sie uns einfach eine E-Mail.',
        href: 'mailto:personaldienstleistungen@nuernberger.de'
      }
    ],
    linkText: 'E-Mail schreiben'
  }
};

export default content;
