import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  Grid,
  Paragraphs
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/wir-ueber-uns';

const WirUeberUnsPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: 'right'
    }}
  >
    <section>
      <SectionHead
        topline={content.geschichte.topline}
        headline={content.geschichte.headline}
        centered
      />
      {content.geschichte.entries.map((entry, index) => (
        <HalvedContent
          key={index}
          align={(index + 1) % 2 === 0 ? 'left' : 'right'}
          swapped={(index + 1) % 2 !== 0}
        >
          <BackgroundImage
            fluid={data[`timeline${index + 1}`].childImageSharp.fluid}
            className={
              (index + 1) % 2 === 0
                ? 'dark-blue-box-shadow'
                : 'grey-turquoise-box-shadow'
            }
          />
          <ContentBox wider>
            <div className="history-headline">
              <time dateTime={entry.year}>{entry.year}</time>
              <h2>{entry.headline}</h2>
            </div>
            <p>{entry.paragraph}</p>
          </ContentBox>
        </HalvedContent>
      ))}
    </section>
    <section className="bg-light-blue text-dark-blue">
      <SectionHead
        topline={content.kompetenzen.topline}
        headline={content.kompetenzen.headline}
      />
      <Grid>
        <div className="children-margin">
          <Paragraphs items={content.kompetenzen.leftParagraphs} />
        </div>
        <div className="children-margin">
          <Paragraphs items={content.kompetenzen.rightParagraphs} innerHTML />
        </div>
      </Grid>
      <Grid>
        {content.kompetenzen.cards.map((card, index) => (
          <Card key={index}>
            <CardHeader color="dark-blue" centered>
              <card.icon />
            </CardHeader>
            <h4 className="text-xl text-center text-dark-blue">
              {card.headline}
            </h4>
          </Card>
        ))}
      </Grid>
    </section>
    <BackgroundImage
      fluid={data.team.childImageSharp.fluid}
      className="full-width-image"
    />
    <section>
      <SectionHead headline={content.kontakt.headline} centered>
        <Paragraphs items={content.kontakt.paragraphs} innerHTML />
      </SectionHead>
      {content.kontakt.boxes.map((box, index) => (
        <HalvedContent
          key={index}
          align="center"
          swapped={(index + 1) % 2 === 0}
        >
          <BackgroundImage
            fluid={data[`contact${index + 1}`].childImageSharp.fluid}
          />
          <ContentBox background={box.background} headline={box.headline} wider>
            <p dangerouslySetInnerHTML={{ __html: box.paragraph }}></p>
            <a
              href={box.href}
              className="arrow-link text-gray-blue arrow-gray-blue"
            >
              {content.kontakt.linkText}
            </a>
          </ContentBox>
        </HalvedContent>
      ))}
    </section>
  </MainLayout>
);

export default WirUeberUnsPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "wir-ueber-uns-full.jpg" }
    ) {
      ...image
    }
    timeline1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "fassade.jpg" }
    ) {
      ...image
    }
    timeline2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "atrium-business-tower.jpg" }
    ) {
      ...image
    }
    team: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "team.jpg" }
    ) {
      ...image
    }
    contact1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-jeansshirt-and-wrist-watch.jpg" }
    ) {
      ...image
    }
    contact2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-smiling-outside.jpg" }
    ) {
      ...image
    }
    contact3: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-wearing-lightblue-shirt-laughing.jpg" }
    ) {
      ...image
    }
  }
`;
